import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';

import { DemoMaterialModule} from './demo-material-module';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireAuthModule } from 'angularfire2/auth';


import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { BlankComponent } from './blank/blank.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormGroup , FormControl  } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { ErrorComponent } from './error/error.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';

import {AuthService } from './auth.service';
import { MessagetimePipe } from './messagetime.pipe';
import { ChatComponent } from './chat/chat.component';
import { ChattimePipe } from './chattime.pipe';
import { RoomMetaService } from './room-meta.service';
import { CovalentDialogsModule } from '@covalent/core/dialogs';
import { OrderModule } from 'ngx-order-pipe';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    LoginComponent,
    BlankComponent,
    ErrorComponent,
    ActivityLogComponent,
    MessagetimePipe, 
    ChatComponent, 
    ChattimePipe
   
  ],
  imports: [
    BrowserModule,    
      OrderModule,
     BrowserAnimationsModule,
     NoopAnimationsModule,
     CovalentDialogsModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,  
    HttpModule,
    HttpClientModule,
    SharedModule,  
    RouterModule.forRoot(AppRoutes),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule // imports firebase/storage only needed for storage features
  

  ],
  providers: [
  {
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
  AuthService,
  RoomMetaService,
  AppComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
