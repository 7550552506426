import { Pipe, PipeTransform , OnDestroy} from '@angular/core';

@Pipe({
  name: 'chattime'
})
export class ChattimePipe implements PipeTransform {
  private timer: number;

  transform(value: any, args?: any): any {
     this.removeTimer();
    //console.log(value);
		let d = this.dateString2Date(value) ;
    //console.log(d);
    return this.formatDate(d);

	}

  // ngOnDestroy(): void {
	// 	this.removeTimer();
	// }
	private removeTimer() {
		if (this.timer) {
			window.clearTimeout(this.timer);
			this.timer = null;
		}
	}
	private getSecondsUntilUpdate(seconds:number) {
		let min = 60;
		let hr = min * 60;
		let day = hr * 24;
		if (seconds < min) { // less than 1 min, update ever 2 secs
			return 2;
		} else if (seconds < hr) { // less than an hour, update every 30 secs
			return 30;
		} else if (seconds < day) { // less then a day, update every 5 mins
			return 300;
		} else { // update every hour
			return 3600;
		}
	}

dateString2Date(dateString:any) {
    dateString = String(dateString);
    if (dateString.indexOf('/') >= 0 ) {
      var dt = dateString.split(',');
      var date= dt[0].split('/');
      var time = dt[1].split(":")
     //console.log(date[2],date[1],date[0],time[0],time[1],time[2]);
      return new Date(date[2],date[1]-1,date[0],time[0],time[1],time[2]);
    }
    else if(dateString.indexOf('-') >= 0 ) {
      var dt = dateString.split(' ');
      var date= dt[0].split('-');
      var time = dt[1].split(":")
  //   console.log(date[2],date[1],date[0],time[0],time[1],time[2]);
     return new Date(date[2],date[1]-1,date[0],time[0],time[1],time[2]);
    }else {
     //console.log(dateString);
      //var t = new Date(dateString);
      var t = new Date( parseFloat(dateString));
    // console.log(t);
      return t;
    }

  }

  formatDate(date) {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  var hour = date.getHours();
  var minutes = date.getMinutes();

  return day + ' ' + monthNames[monthIndex] + ' ' + year+" - "+hour+":"+minutes;
}

}
