import {  Component} from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { AuthService} from './auth.service';
import { BehaviorSubject } from '../../node_modules/rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  LoggedIn = false;
  userSub; 
  admin;
  userUid;
  user;
  active_bot: BehaviorSubject<any>;
  constructor(public afAuth: AngularFireAuth, public router: Router, private _afs: AngularFirestore,  private authService: AuthService){
    this.active_bot = new BehaviorSubject('');
    this.authService.afAuth.authState.subscribe(
      (auth) =>{

        if(auth == null){
          this.LoggedIn = false;
          this.router.navigate(['login']);
          // this.admin = this._afs.doc('admin/'+auth.uid).valueChanges();
          // this.admin.subscribe(admin =>{
          //   console.log(admin);
          //   if(admin){

          //   }
          // });
        }else{
          this.LoggedIn = true;
          this.userUid = auth.uid;
          this.user = auth.email;
          this.router.navigate(['main']);
          console.log(auth.uid);
          this.admin = this._afs.doc('admin/'+this.userUid).valueChanges();
          this.admin.subscribe(admin =>{
            console.log(admin);

            this.authService.getAdminData(admin);
            this.active_bot.next(admin.active_bot);
          });
        }
      });


  //  this.userSub= this.afAuth.user.subscribe(user =>{
  //     console.log(user);
  //       if(user !== null){
  //         this.router.navigate(['main']);

  //       }else{
  //         this.router.navigate(['login']);
  //       }

  //       this.userSub.unsubscribe();
  //   });
  }


}
