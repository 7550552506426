import { Component, OnInit } from '@angular/core';
// import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';

// import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { AuthService} from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email;
  password;
  LoggedIn = false;
  constructor(public authService: AuthService, public router: Router) {
  }
  ngOnInit() {
  }


 login(email,password){
  this.authService.login(email,password).then((data) =>{
    this.router.navigate(['main'])
  }).catch(error =>{
      //need to display user error
      console.log("error", error);
   
  });
 }


}
