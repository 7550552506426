import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewChecked } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { ActivatedRoute } from '@angular/router';
import { RoomMetaService } from '../room-meta.service';
import { ViewContainerRef } from '@angular/core';
import { TdDialogService } from '@covalent/core/dialogs';
import { AngularFirestore } from '../../../node_modules/angularfire2/firestore';
import { Http } from '@angular/http';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked {

  authUser;
  sub;
  chatId;
  messages = [];
  room;
  bot_status;
  mes_sub;
  room_sub;
  admin_det;
  active_bot;
  chat_message = '';

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  constructor(private _AuthServ: AngularFireAuth, private route: ActivatedRoute, private _chat: RoomMetaService,
    private _dialogService: TdDialogService, private db: AngularFirestore, private http: Http,
    private _viewContainerRef: ViewContainerRef) {

    this._AuthServ.authState.subscribe(user => {
      this.authUser = user;
      this.db.doc('admin/' + user.uid).valueChanges()
        .subscribe((admin: any) => {
          this.admin_det = admin;
          this._chat.getBot(admin.active_bot).subscribe(bot => {
            console.log(bot);
            this.active_bot = bot[0];
          });
        });
    });

  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.chatId = params['id'];
      this.getRoomMess(this.chatId);
      this.getRoom(this.chatId);
    });
    this.sub.unsubscribe();

  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.mes_sub.unsubscribe();
    this.room_sub.unsubscribe();

  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  getRoomMess(id: any) {
    this.mes_sub = this._chat.getMessages(id)
      .subscribe((snapshots: any) => {
        console.log(snapshots);

        if (snapshots !== undefined) {
          // const tempKeys = Object.keys(snapshots);
          // // console.log(Object.keys(snapshots).length);
          // // console.log(snapshots[tempKeys[0]]);
          // tempKeys.forEach(key => {
          //   this.messages.push(snapshots[key]);
          // });
          // console.log(this.messages);
          this.messages = snapshots;
          this.scrollToBottom();

        }
        // tslint:disable-next-line:one-line
        else { this.messages = []; }
      });



  }

  getRoom(id: any) {
    this.room_sub = this._chat.getRoom(id)
      .subscribe((snapshots: any) => {
        console.log(snapshots);

        if (snapshots !== undefined) {
          if (snapshots.mute_bot === 'true') {
            console.log(true);
            this.room = snapshots;
            this.bot_status = true;
          } else {
            console.log(false);
            this.room = snapshots;
            this.bot_status = false;
          }
        } else if (snapshots === undefined) {
          this._chat.getUser(id)
            .subscribe((user:any) => {
              console.log(user);

              const temp_room = {
                bot_id: user.bot_id,
                createdAt: Date.now(),
                mcb_id: this.chatId,
                mute_bot: 'false',
                name: user.name,
                user_id: user.user_id
              };
              this.room = temp_room;
              this._chat.add_room(this.room);

            });
        }

        //   snapshots.forEach(snapshot => {
        //     //  console.log(snapshot.$key+"  -- "+id);

        //     if (snapshot.$key === id) {
        //        console.log(snapshot);
        //       this.room = snapshot;

        //     }
        //   });
        //   if(this.room === undefined){
        //   this._userService.getUsers().subscribe(snapshots =>{

        //     snapshots.forEach(snapshot =>{
        //       if(snapshot.$key === id){
        //         var loc = 'chat/room-metadata/'+snapshot.$key;
        //         const mesObj = this.db.object(loc,{ preserveSnapshot: true }).update({
        //             bot_id: snapshot.bot_id,
        //             createdAt: Date.now(),
        //             mcb_id: snapshot.$key,
        //             mute_bot:'false',
        //             name: snapshot.name,
        //             user_id: snapshot.user_id
        //           }).then(mes => {console.log("new room created")

        //           this.room = {
        //             bot_id: snapshot.bot_id,
        //           createdAt: Date.now(),
        //           mcb_id: snapshot.$key,
        //           mute_bot:'false',
        //           name: snapshot.name,
        //           user_id: snapshot.user_id
        //         }



        //         })

        //       }
        //     });

        //   });

        // }
      });

  }

  switchOFF() {
    this._dialogService.openConfirm({
      message: 'Would you like to perform this silently :',
      viewContainerRef: this._viewContainerRef, //OPTIONAL
      disableClose: true, // defaults to false
      title: 'Turn OFF', //OPTIONAL, hides if not provided
      cancelButton: 'No', //OPTIONAL, defaults to 'CANCEL'
      acceptButton: 'Yes', //OPTIONAL, defaults to 'ACCEPT'
      width: '500px', //OPTIONAL, defaults to 400px
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        // DO SOMETHING
        console.log("silentLy switching off bot ");
        const bot_id = this.room.bot_id,
          // mcb_id = 'bot',
          action = 'Mute',
          user_id = this.room.user_id,
          msg = "";
        // var loc = 'chat/room-messages/'+this.chatId;
        const mesObj = this.db.collection('room-metadata/' + this.room.mcb_id + '/room-messages').add({
          message: "****SILENTLY SWITCHING BOT OFF****",
          name: this.active_bot.bot_name,
          timestamp: Date.now(),
          type: 'notification',
          mcb_id: "bot",
          msg_status: "pending"
        }).then(mes => {
          console.log(mes.id);
          const id = mes.id;
          // tslint:disable-next-line:max-line-length
          const url = 'https://flowxo.com/hooks/b/73484852?only_bot_id=' + bot_id + "&only_user_id=" + user_id + "&action=" + action + "&Msg=" + msg + "&msg_id=" + id + "&mcb_id=" + this.chatId;
          console.log(url);

          this.http.get(url)
            .map(response => {
              console.log(response);
              //  const mesobj = this.db.list(loc).update(id, {msg_status: "delivered"});
              //
            }).subscribe(res => {

              this.db.collection('activityLog').add({
                timestamp: Date.now(),
                mcb_id: this.chatId,
                activity: 'active'
              });

              setTimeout(() => {
                const timeout_ref = this.db.doc('room-metadata/' + this.room.mcb_id + '/room-messages/' + id);
                timeout_ref.valueChanges()
                  .subscribe((message: any) => {
                    if (message.msg_status === 'pending') {
                      timeout_ref.update({
                        ...message,
                        msg_status: 'failed'
                      });

                    }
                    else {
                      var loc = 'room-metadata/' + this.room.mcb_id;
                      const mesObj = this.db.collection(loc).add({
                        ...this.room,
                        createdAt: Date.now(),
                        mute_bot: 'false'
                      }).then(mes => { console.log("time updated") });
                      this.room.mute_bot = 'false';
                    }
                  });



              }, 10000);

            });
        }).catch(err => console.log(err, 'You do not have access!'));
      } else {
        // DO SOMETHING ELSE
        console.log("switching off bot ");
        const bot_id = this.room.bot_id,
          mcb_id = "bot",
          action = "Mute",
          user_id = this.room.user_id,
          msg = this.active_bot.bot_name + ' is now offline whilst you chat with a Customer Care representative.';
        // var loc = 'room-metadata/'+this.chatId;
        console.log({
          message: msg,
          name: this.active_bot.bot_name,
          timestamp: Date.now(),
          type: 'notification',
          mcb_id: 'bot',
          msg_status: 'pending'
        });
        const mesObj = this.db.collection('room-metadata/' + this.room.mcb_id + '/room-messages').add({
          message: msg,
          name: this.active_bot.bot_name,
          timestamp: Date.now(),
          type: 'notification',
          mcb_id: 'bot',
          msg_status: "pending"
        }).then(mes => {
          console.log(mes.id);
          const id = mes.id;
          const url = "https://flowxo.com/hooks/b/73484852?only_bot_id=" + bot_id + "&only_user_id=" + user_id + "&action=" + action + "&Msg=" + msg + "&msg_id=" + id + "&mcb_id=" + this.chatId;
          console.log(url);

          this.http.get(url)
            .map(response => {
              console.log(response);
              //  const mesobj = this.db.list(loc).update(id, {msg_status: "delivered"});
              //  this.room.mute_bot = 'true';

            }).subscribe(res => {
              console.log(res)

              this.db.collection('activityLog').add({
                timestamp: Date.now(),
                mcb_id: this.chatId,
                activity: 'active'
              });

              setTimeout(() => {
                const timeout_ref = this.db.doc('room-metadata/' + this.room.mcb_id + '/room-messages/' + id);
                timeout_ref.valueChanges()
                  .subscribe((message: any) => {
                    if (message.msg_status == 'pending') {
                      timeout_ref.update({
                        ...message,
                        msg_status: 'failed'
                      });
                    }
                    else if (message.msg_status === 'delivered') {
                      const loc = 'room-metadata/' + this.room.mcb_id;

                      this.db.collection(loc).add({
                        ...this.room,
                        createdAt: Date.now(),
                        mute_bot: 'false'
                      }).then(mes => {
                        console.log("time updated")
                        this.room.mute_bot = 'false';
                      });

                    }
                  });



              }, 10000);

            });
        }).catch(err => console.log(err, 'You do not have access!'))
      }
    });
  }


  switchON() {
    this._dialogService.openConfirm({
      message: 'Would you like to perform this silently :',
      viewContainerRef: this._viewContainerRef, //OPTIONAL
      disableClose: true, // defaults to false
      title: 'Turn ON', //OPTIONAL, hides if not provided
      cancelButton: 'No', //OPTIONAL, defaults to 'CANCEL'
      acceptButton: 'Yes', //OPTIONAL, defaults to 'ACCEPT'
      width: '500px', //OPTIONAL, defaults to 400px
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        // DO SOMETHING
        console.log("silentLy switching ON bot ");
        const bot_id = this.room.bot_id,
          // mcb_id = 'bot',
          action = 'Wake',
          user_id = this.room.user_id,
          msg = "";
        // var loc = 'chat/room-messages/'+this.chatId;
        const mesObj = this.db.collection('room-metadata/' + this.room.mcb_id + '/room-messages').add({
          message: "****SILENTLY SWITCHING BOT ON****",
          name: this.active_bot.bot_name,
          timestamp: Date.now(),
          type: 'notification',
          mcb_id: "bot",
          msg_status: "pending"
        }).then(mes => {
          console.log(mes.id);
          const id = mes.id;
          // tslint:disable-next-line:max-line-length
          const url = 'https://flowxo.com/hooks/b/73484852?only_bot_id=' + bot_id + "&only_user_id=" + user_id + "&action=" + action + "&Msg=" + msg + "&msg_id=" + id + "&mcb_id=" + this.chatId;
          console.log(url);

          this.http.get(url)
            .map(response => {
              console.log(response);
              //  const mesobj = this.db.list(loc).update(id, {msg_status: "delivered"});
              //
            }).subscribe(res => {

              this.db.collection('activityLog').add({
                timestamp: Date.now(),
                mcb_id: this.chatId,
                activity: 'resolved'
              });

              setTimeout(() => {
                const timeout_ref = this.db.doc('room-metadata/' + this.room.mcb_id + '/room-messages/' + id);
                timeout_ref.valueChanges()
                  .subscribe((message: any) => {
                    if (message.msg_status === 'pending') {
                      timeout_ref.update({
                        ...message,
                        msg_status: 'failed'
                      });

                    }
                    else {
                      var loc = 'room-metadata/' + this.room.mcb_id;
                      const mesObj = this.db.collection(loc).add({
                        ...this.room,
                        createdAt: Date.now(),
                        mute_bot: 'true'
                      }).then(mes => { console.log("time updated") });
                      this.room.mute_bot = 'true';
                    }
                  });



              }, 10000);

            });
        }).catch(err => console.log(err, 'You do not have access!'));
      } else {
        // DO SOMETHING ELSE
        console.log("switching ON bot ");
        const bot_id = this.room.bot_id,
          mcb_id = "bot",
          action = "Wake",
          user_id = this.room.user_id,
          msg = this.active_bot.bot_name + ' is back ONLINE!';
        // var loc = 'room-metadata/'+this.chatId;
        console.log({
          message: msg,
          name: this.active_bot.bot_name,
          timestamp: Date.now(),
          type: 'notification',
          mcb_id: 'bot',
          msg_status: 'pending'
        });
        const mesObj = this.db.collection('room-metadata/' + this.room.mcb_id + '/room-messages').add({
          message: msg,
          name: this.active_bot.bot_name,
          timestamp: Date.now(),
          type: 'notification',
          mcb_id: 'bot',
          msg_status: "pending"
        }).then(mes => {
          console.log(mes.id);
          const id = mes.id;
          const url = "https://flowxo.com/hooks/b/73484852?only_bot_id=" + bot_id + "&only_user_id=" + user_id + "&action=" + action + "&Msg=" + msg + "&msg_id=" + id + "&mcb_id=" + this.chatId;
          console.log(url);

          this.http.get(url)
            .map(response => {
              console.log(response);
              //  const mesobj = this.db.list(loc).update(id, {msg_status: "delivered"});
              //  this.room.mute_bot = 'true';

            }).subscribe(res => {
              console.log(res)

              this.db.collection('activityLog').add({
                timestamp: Date.now(),
                mcb_id: this.chatId,
                activity: 'resolved'
              });

              setTimeout(() => {
                const timeout_ref = this.db.doc('room-metadata/' + this.room.mcb_id + '/room-messages/' + id);
                timeout_ref.valueChanges()
                  .subscribe((message: any) => {
                    if (message.msg_status == 'pending') {
                      timeout_ref.update({
                        ...message,
                        msg_status: 'failed'
                      });
                    }
                    else if (message.msg_status === 'delivered') {
                      const loc = 'room-metadata/' + this.room.mcb_id;

                      this.db.collection(loc).add({
                        ...this.room,
                        createdAt: Date.now(),
                        mute_bot: 'true'
                      }).then(mes => { console.log("time updated") });
                      this.room.mute_bot = 'true';
                    }
                  });



              }, 10000);

            });
        }).catch(err => console.log(err, 'You do not have access!'))
      }
    });
  }

  sendMessage(message) {
    console.log(message);
    console.log(this.chat_message);
    console.log(this.authUser.id);
    console.log(this.chatId);
    // var loc = 'chat/room-messages/'+this. chatId;
    console.log({
      message: this.chat_message,
      name: this.admin_det.name,
      timestamp: Date.now(),
      type: 'default',
      mcb_id: 'admin',
      msg_status: "pending"
    });
    this.db.collection('room-metadata/' + this.room.mcb_id + '/room-messages').add({
      message: this.chat_message,
      name: this.admin_det.name,
      timestamp: Date.now(),
      type: 'default',
      mcb_id: 'admin',
      msg_status: "pending"
    }).then(mes => {

      console.log(mes.id);
      const id = mes.id;
      const bot_id = this.room.bot_id,
        user_id = this.room.user_id,

        action = "Message",
        msg = this.chat_message;
      var url = "https://flowxo.com/hooks/b/73484852?only_bot_id=" + bot_id + "&only_user_id=" + user_id + "&action=" + action + "&Msg=" + msg + "&msg_id=" + id + "&mcb_id=" + this.chatId;
      console.log(url);

      this.http.get(url)
        .map(response => {
          console.log(response);
          //const mesobj = this.db.list(loc).update(id, {msg_status: "delivered"});

          this.chat_message = "";
        }).subscribe(res => {
          console.log(res)
          const timeout_ref = this.db.doc('room-metadata/' + this.room.mcb_id);
          // timeout_ref.valueChanges()
          timeout_ref.update({
            createdAt: Date.now()
          }).then(mes => { console.log("time updated") });

        });
    })
      .catch(err => console.log(err, 'You do not have access!'))
  }

}
