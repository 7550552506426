import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  user;
    constructor(public afAuth: AngularFireAuth, public router: Router){
      this.afAuth.authState.subscribe(user =>{
        this.user = user;
      });
    }
  ngOnInit() {
  }

  return(){
    this.afAuth.auth.signOut();
    this.router.navigate(['login']);
  }

}
