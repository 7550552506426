import { ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild, HostListener, Directive, AfterViewInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
// import { AuthService} from './auth.service';
import { AuthService } from '../../../auth.service';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { RoomMetaService } from '../../../room-meta.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  user;
  bots;
  uid;
  active_bot;
  private _mobileQueryListener: () => void;


  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public menuItems: MenuItems,
     public afAuth: AngularFireAuth ,public _auth: AuthService, public router: Router, public _afs: AngularFirestore,
      public _bot: RoomMetaService) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    //  this.afAuth.authState.subscribe(user =>{
    //   this.user = user;
    // });
    this._auth.user.subscribe(data => {
      data;
      this.uid =data.uid;
      this._afs.doc('admin/'+data.uid).valueChanges()
      .subscribe((admin:any) =>{
        console.log(admin);
        this.user = admin;
        this.active_bot = admin.active_bot;
      });
    });
    this._bot.getAllBots().subscribe(bots =>{
      this.bots =bots;
    });

    // this._afs.collection('')
     
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  signOut(){
    this.afAuth.auth.signOut();
    this.router.navigate(['login']);
  }
  updateBot(bot){
    console.log(bot);
    this._afs.doc('admin/'+this.uid)
    .update({
      active_bot : bot.bot_id
    });
  }
 
}