import { Component } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  user;
    constructor(public afAuth: AngularFireAuth, public router: Router){
      this.afAuth.authState.subscribe(user =>{
        this.user = user;
      });
    }
    signOut(){
      this.afAuth.auth.signOut();
      this.router.navigate(['login']);
    }
}
