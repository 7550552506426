import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AppComponent } from './app.component';

@Injectable({
  providedIn: 'root'
})
export class RoomMetaService {

  // active_rooms;
  // resolved_rooms;
  active_rooms_no_limit;
  resolved_rooms_no_limit;


  // notifications;
  main_bot= '';
  constructor(private _afs: AngularFirestore, private _auth: AppComponent) {
    this._auth.active_bot.subscribe(active_bot =>{
      console.log("new Active bot ", active_bot);
      this.main_bot = active_bot;
    });
  }

  notifications(bot){
    return this._afs.collection('notifications', ref => ref.where('bot_id', '==', bot).orderBy('time_stamp').limit(50)).valueChanges();
  }
  resolved_rooms(bot){
    // tslint:disable-next-line:max-line-length
    console.log(bot);
    return this._afs.collection('room-metadata', ref => ref.where('mute_bot', '==', 'false').where('bot_id', '==', bot).orderBy('createdAt').limit(100)).valueChanges();

  }
  active_rooms(bot){
    // tslint:disable-next-line:max-line-length
    console.log(bot);
    return this._afs.collection('room-metadata', ref => ref.where('mute_bot', '==', 'true').where('bot_id', '==', bot).orderBy('createdAt').limit(100)).valueChanges();

  }
  active_rooms_no_limit_length(bot){
    // tslint:disable-next-line:max-line-length
    return this._afs.collection('room-metadata', ref => ref.where('mute_bot', '==', 'true').where('bot_id', '==', bot).orderBy('createdAt')).valueChanges();
  }
  resolved_rooms_no_limit_length(bot){
  // tslint:disable-next-line:max-line-length
  return this._afs.collection('room-metadata', ref => ref.where('mute_bot', '==', 'false').where('bot_id', '==', bot).orderBy('createdAt')).valueChanges();

  }

  getMessages(id) {
    return this._afs.collection('room-metadata/'+ id+'/room-messages/').valueChanges();
  }
  getRoom(id) {
    return this._afs.doc('room-metadata/' + id).valueChanges();
  }
  getUser(id){
    return this._afs.doc('users/' + id).valueChanges();
  }
  add_room(room_obj){
    this._afs.collection('room-metadata').doc(room_obj.mcb_id).set(room_obj);
  }
  searchUser(text) {
    return this._afs.collection('room-metadata', ref => ref.where('name', '>', text)).valueChanges();

  }
  getAllBots(){
    return this._afs.collection('bots').valueChanges();
  }
  getBot(bot_id){
    return this._afs.collection('bots', ref => ref.where('bot_id','==',bot_id)).valueChanges();
  }
}
