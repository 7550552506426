import {Pipe, PipeTransform, NgZone, ChangeDetectorRef} from "@angular/core";

@Pipe({
  name: 'messagetime'
})
export class MessagetimePipe implements PipeTransform {
	private timer: number;
	constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {}
	transform(value:string) {
		this.removeTimer();
    //console.log(value);
		let d = this.dateString2Date(value) ;
    //console.log(d);

		let now = new Date();
		let seconds = Math.round(Math.abs((now.getTime() - d.getTime())/1000));
		let timeToUpdate = this.getSecondsUntilUpdate(seconds) *1000;
		this.timer = this.ngZone.runOutsideAngular(() => {
			if (typeof window !== 'undefined') {
				return window.setTimeout(() => {
					this.ngZone.run(() => this.changeDetectorRef.markForCheck());
				}, timeToUpdate);
			}
			return null;
		});
		let minutes = Math.round(Math.abs(seconds / 60));
		let hours = Math.round(Math.abs(minutes / 60));
		let days = Math.round(Math.abs(hours / 24));
		let months = Math.round(Math.abs(days/30.416));
		let years = Math.round(Math.abs(days/365));
		if (seconds <= 45) {
			return 'a few seconds ago';
		} else if (seconds <= 90) {
			return '1 minute ago';
		} else if (minutes <= 45) {
			return minutes + ' minutes ago';
		} else if (minutes <= 90) {
			return '1 hour ago';
		} else if (hours <= 22) {
			return hours + ' hours ago';
		} else if (hours <= 36) {
			return 'a day ago';
		} else if (days <= 25) {
			return days + ' days ago';
		} else if (days <= 45) {
			return '1 month ago';
		} else if (days <= 345) {
			return months + ' months ago';
		} else if (days <= 545) {
			return '1 year ago';
		} else { // (days > 545)
			return years + ' years ago';
		}
	}
	ngOnDestroy(): void {
		this.removeTimer();
	}
	private removeTimer() {
		if (this.timer) {
			window.clearTimeout(this.timer);
			this.timer = null;
		}
	}
	private getSecondsUntilUpdate(seconds:number) {
		let min = 60;
		let hr = min * 60;
		let day = hr * 24;
		if (seconds < min) { // less than 1 min, update ever 2 secs
			return 2;
		} else if (seconds < hr) { // less than an hour, update every 30 secs
			return 30;
		} else if (seconds < day) { // less then a day, update every 5 mins
			return 300;
		} else { // update every hour
			return 3600;
		}
	}

dateString2Date(dateString:any) {
    dateString = String(dateString);
    if (dateString.indexOf('/') >= 0 ) {
      var dt = dateString.split(',');
      var date= dt[0].split('/');
      var time = dt[1].split(":")
     //console.log(date[2],date[1],date[0],time[0],time[1],time[2]);
      return new Date(date[2],date[1]-1,date[0],time[0],time[1],time[2]);
    }
    else if(dateString.indexOf('-') >= 0 ) {
      var dt = dateString.split(' ');
      var date= dt[0].split('-');
      var time = dt[1].split(":")
  //   console.log(date[2],date[1],date[0],time[0],time[1],time[2]);
     return new Date(date[2],date[1]-1,date[0],time[0],time[1],time[2]);
    }else {
     //console.log(dateString);
      //var t = new Date(dateString);
      var t = new Date( parseFloat(dateString));
    // console.log(t);
      return t;
    }

  }
}
