import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<any>;
  admin: Observable<any>;
  adminData: Observable<any>;

  constructor(public afAuth: AngularFireAuth) { 
    this.user = afAuth.authState;

  }
  login(email: string,password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email,password);
}
getAdminData(admin){
  this.adminData = admin;
}
admin_data(){
  return this.adminData;
}
active_bot(){
  return this.adminData;
}

logout() {
   return this.afAuth.auth.signOut();
}
getUser(){
  return this.user;
}

}
