// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyD-o1Tezql6OywGCjb0OaJI1Obku0Qni8w",
    authDomain: "makebetterchat.firebaseapp.com",
    databaseURL: "https://makebetterchat.firebaseio.com",
    projectId: "makebetterchat",
    storageBucket: "makebetterchat.appspot.com",
    messagingSenderId: "99365423785"
  }
};
