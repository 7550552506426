import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { BlankComponent } from './blank/blank.component';
import {ErrorComponent } from './error/error.component';
import {ActivityLogComponent} from './activity-log/activity-log.component';
import {ChatComponent} from './chat/chat.component';

export const AppRoutes: Routes = [{
  path: 'main',
  component: FullComponent,
  children: [{ 
    path: '', 
    redirectTo: '/main/chat/new-queries', 
    pathMatch: 'full' 
  },
  {
    path: 'activity-log',
    component: ActivityLogComponent
  },
  {
    path: '',
    loadChildren: './material-component/material.module#MaterialComponentsModule'
  }, {
    path: 'chat',
    loadChildren: './starter/starter.module#StarterModule'
  },
  {
    path: 'dm/:id',
    component: ChatComponent
  }
]
},
{
  path: 'blank',
    component: BlankComponent
},
{
  path: 'error',
  component: ErrorComponent
},
{
  path: 'login',
  component: LoginComponent
},
{ path: '',
  redirectTo: 'blank',
  pathMatch: 'full'
},
{ path: '**', component: BlankComponent }];

